import React from "react"
import { graphql, Link } from "gatsby"

import { Layout, SEO } from "../components"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div
        className="[ flow ]"
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          height: `500px`,
          alignItems: `center`,
        }}
      >
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <div className="[ back-to-somewhere ]">
          <Link to="/" className="[ link-hover ]">
            Back to home
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
